import React from 'react'

const Socials = () => {
  return(
    <div className="socials">
      <ul>
        <li>
          <a className="action" href="https://www.instagram.com/casavasalto/" target="__blank">IG</a>
        </li>
        <li>
          <a className="action" href="https://www.facebook.com/CasaVasalto" target="__blank">FB</a>
        </li>
      </ul>
    </div>
  )
}

export default Socials
