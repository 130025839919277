import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import Footer from '../layout/footer'

import Logo from '../../assets/svg/logo-white.svg'

const MenuMobile = () => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const footer = document.querySelector('footer')

    if (!!isOpen) footer.classList.add('white')
  })

  return(
    <>
      <button
        type="button"
        aria-label="Toggle Menu"
        className={isOpen === true ? 'menu-open white' : 'menu-open'}
        onClick={() => setOpen(!isOpen)}>
          {isOpen === true ? 'Close' : 'Menu'}
      </button>
      <div className={isOpen === true ? 'menu open' : 'menu'}>
        <Logo className="logo-white"/>
        <ul>
          <li><Link onClick={() => setOpen(false)} to="/">Home</Link></li>
          <li><Link onClick={() => setOpen(false)} to="/stories">Stories</Link></li>
          <li><Link onClick={() => setOpen(false)} to="/contact">Contact</Link></li>
        </ul>
        <Footer />
      </div>
    </>
  )
}

export default MenuMobile
