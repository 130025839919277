import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const Footer = () => {
  const { sanityContact } = useStaticQuery(
    graphql`
      query {
        sanityContact {
          copyright
          info {
            mail
          }
        }
      }
    `
  )
  const { copyright, info } = sanityContact

  return(
    <footer className="grid">
      <p className="fb"><a className="action-gray" target="__blank" href="https://www.facebook.com/CasaVasalto">FB</a></p>
      <p className="ig"><a className="action-gray" target="__blank" href="https://www.instagram.com/casavasalto/">IG</a></p>
      <p className="rights">{copyright}</p>
      <p className="mail"><a className="action-gray" href={`mailto:${info.mail}`}>{info.mail}</a></p>
      <p className="privacy"><Link className="action-gray" to="/privacy-policy">Privacy Policy</Link></p>
    </footer>
  )
}

export default Footer
