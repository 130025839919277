import * as React from "react"
import { Link } from 'gatsby'

const Nav = () => {
  return(
    <nav>
      <ul>
        <li><Link className="action" to="/">Home</Link></li>
        <li><Link className="action" to="/stories">Stories</Link></li>
        <li><Link className="action" to="/contact">Contact</Link></li>
      </ul>
    </nav>
  )
}

export default Nav
