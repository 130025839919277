import React from "react"
import PropTypes from "prop-types"
import { Link } from 'gatsby'

import LogoWhite from '../../assets/svg/logo-white.svg'
import Nav from './nav'
import MenuMobile from './menu'
import Socials from './socials'
import Footer from './footer'

import '../../styles/custom.scss'
import '../../styles/media.scss'

const Layout = ({ children }) => {
  return (
    <>
      <Link to="/">
        <LogoWhite className="logo" />
      </Link>
      <MenuMobile />
      <Nav />
      <Socials />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
